import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios'

export const setRelationships = (state, payload) => {
    const relationships = {}

    payload.forEach(({ type, id, attributes }) => {
        if (!relationships[type]) relationships[type] = {}
        relationships[type][id] = { id, ...attributes }
    });

    return relationships
};

export const BaseSlice = (identifier, api, initialState, transformer = null, customReducers = {}, extraReducers = {}) => {
    initialState = initialState || {
        rows: [],
        metaData: {},
        loading: false,
        error: false,
        selected: {}
    };

    const List = createAsyncThunk(
        `@${identifier}/index`, async (params, thunkApi) => { return await axios.get(api, { params }) }
    );

    const Show = createAsyncThunk(
        `@${identifier}/show`, async (id, thunkApi) => await axios.get(`${api}/${id}`)
    );

    const Create = createAsyncThunk(
        `@${identifier}/create`, async (params, thunkApi) => await axios.post(api, params)
    );

    const Delete = createAsyncThunk(
        `@${identifier}/delete`, async (id, thunkApi) => await axios.delete(`${api}/${id}`)
    );

    const Update = createAsyncThunk(
        `@${identifier}/update`, async ({ id, ...params }, thunkApi) => await axios.put(`${api}/${id}`, params)
    );

    const Slice = createSlice({
        name: identifier,
        initialState,
        reducers: customReducers,
        extraReducers: {
            [List.pending]: (state) => {
                state.isLoading = true;
            },
            [List.fulfilled]: (state, { payload: { data: { meta, data, included = [] } } }) => {
                state.rows = data;
                state.relationships = setRelationships(state, included)
                state.metaData = meta;
                state.reload = false
                state.is_created = false;
                state.isLoading = false;
                if (state.status === 'edited') {
                    state.status = 'reloaded'

                } else {
                    state.status = 'new'
                }
            },
            [List.rejected]: (state, action) => {
                state.rows = [];
                state.isLoading = false;
            },
            [Create.fulfilled]: (state, action) => {
                state.reload = true;
                state.is_created = true;
                state.status = 'edited'
            },
            [Update.fulfilled]: (state, action) => {
                state.reload = true;
                state.is_created = true;
                state.status = 'edited'
            },
            [Delete.fulfilled]: (state, action) => {
                state.reload = true;
                state.status = 'edited'
            },
            [Show.fulfilled]: (state, { payload: { data: { meta, data, included = [] } } }) => {
                const selected = { ...data.attributes, relationships: setRelationships(state, included) }
                state.selected = transformer ? transformer(selected) : selected
            },
            ...extraReducers
        },
    });

    return { List, Show, Create, Delete, Update, reducer: Slice.reducer, slice: Slice }
}
