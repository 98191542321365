import { BaseSlice, setRelationships } from 'features/baseSlice';
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';

export const changeEntryRecordsDate = createAsyncThunk(
  "@EntryRecord/changeEntryRecordsDate", async (params, thunkApi) => {
    try {
      return await axios.put('/api/v1/entry_records/change_date', params);
    } catch (err) {
      return thunkApi.rejectWithValue({
        ...err.response.data,
        status: err.response.status,
      });
    } 
  }
);

export const getEntryRecordDay = createAsyncThunk(
  "@EntryRecord/getEntryRecordDay", async (id, thunkApi) => await axios.get(`/api/v1/entry_record_days/${id}`)
);

const initialState = {
  rows: [],
  metaData: {},
  isLoading: false,
  error: false,
  selected: {},
  isRecordDateChanged: false
};

const bslice = BaseSlice(
  'EntryRecordDay',
  '/api/v1/entry_record_days',
  initialState,
  null,
  {
    setDefaults: (state) => {}
  },
  {
    [changeEntryRecordsDate.pending]: (state) => {
      state.isRecordDateChanged = false;
      state.isLoading = true;
    },
    [changeEntryRecordsDate.fulfilled]: (state, { payload: { data: { data, meta } } }) => {
      state.isRecordDateChanged = true;
      state.isLoading = false;
    },
    [getEntryRecordDay.pending]: (state) => {
      state.isLoading = true;
    },
    [getEntryRecordDay.fulfilled]: (state, { payload: { data: { meta, data, included = [] } } }) => {
      const selected = { ...data.attributes, relationships: setRelationships(state, included) }
      state.selected = selected;
      state.isRecordDateChanged = false;
    },
  }
);
const { reducer, slice: EntryRecordDaySlice } = bslice;

// export const getOrder = Show;
// export const createOrder = Create;
// export const deleteOrder = Delete;
// export const updateOrder = Update;
export const { setDefaults } = EntryRecordDaySlice.actions;

export default reducer;
