import { persistReducer } from 'redux-persist';
import { combineReducers } from "redux";
import storage from 'redux-persist/lib/storage';

import accountReducer from './accountReducer';

// reducer import
import customizationReducer from './customizationReducer';
import snackbarReducer from './snackbarReducer';
import cartReducer from './cartReducer';
import rawMaterialSlice from 'features/raw_materials/rawMaterialSlice';
import categorySlice from 'features/categories/categorySlice';
import productCategorySlice from 'features/product_categories/productCategorySlice';
import stockPlacementSlice from 'features/stock_placements/stockPlacementSlice';
import supplierSlice from 'features/suppliers/supplierSlice';
import loaderSlice from 'features/loaderSlice';
import productSlice from 'features/products/productSlice';
import semiProductSlice from 'features/products/semiProductSlice';
import ingredientSlice from 'features/recipes/ingredientSlice';
import unitSlice from 'features/units/unitSlice';
import currencySlice from 'features/currencies/currencySlice';
import recipeSlice from 'features/recipes/recipeSlice';
import stockSlice from 'features/stocks/stockSlice';
import clientSlice from 'features/clients/clientSlice';
import nutritionSlice from 'features/nutritions/nutritionsSlice';
import countrySlice from 'features/countries/countrySlice';
import ingredientNameSlice from 'features/products/ingredientName';
import allergenSlice from 'features/allergens/allergenSlice';
import traceSlice from 'features/allergens/traceSlice';
import DurabilitySlice from 'features/durabilities/DurabilitySlice';
import DurabilityCommentSlice from 'features/durabilities/DurabilityCommentSlice';
import orderSlice from 'features/orders/orderSlice';
import purchaseListSlice from 'features/purchases/purchaseListSlice';
import productionSlice from 'features/production/productionSlice';
import productionUnitSlice from 'features/production_units/productionUnitSlice';
import mailerSlice from 'features/mailers/mailerSlice';
import dashboardSlice from 'features/dashboard/dashboardSlice';
import amendOrderLineSlice from 'features/rules/amendOrderLineSlice';
import amendOrderSlice from 'features/rules/amendOrderSlice';
import reportSlice from 'features/reports/reportsSlice';
import addressSlice from 'features/addresses/addressSlice';
import entryRecordSlice from 'features/entry_records/entryRecordSlice';
import entryRecordDaySlice from 'features/entry_record_days/entryRecordDaySlice';
import employeeSlice from 'features/employees/employeeSlice';
import employeeDepartmentSlice from 'features/employees/employeeDepartmentSlice';
import employeeDeviceGroupSlice from 'features/employees/employeeDeviceGroupSlice';
import employeeHolidaySlice from 'features/employees/employeeHolidaySlice';
import payrollDataSlice from 'features/payroll_data/payrollDataSlice';
import payrollUpdateJobSlice from 'features/payroll_update_jobs/payrollUpdateJobsSlice';
import kgroupSlice from 'features/kgroups/kgroupSlice';
import pageSlice from 'features/pageSlice';
import userSlice from 'features/users/userSlice';
import userSettingSlice from 'features/user_settings/userSettingSlice';
import reportTemplateSlice from 'features/report_templates/reportTemplateSlice';
// ===========================|| COMBINE REDUCER ||=========================== //

const reducer = combineReducers({
    account: accountReducer,
    raw_materials: rawMaterialSlice,
    products: productSlice,
    semi_products: semiProductSlice,
    loaderSlice: loaderSlice,
    suppliers: supplierSlice,
    categories: categorySlice,
    product_categories: productCategorySlice,
    stock_placements: stockPlacementSlice,
    customization: customizationReducer,
    ingredients: ingredientSlice,
    nutritions: nutritionSlice,
    snackbar: snackbarReducer,
    units: unitSlice,
    recipes: recipeSlice,
    stocks: stockSlice,
    clients: clientSlice,
    countries: countrySlice,
    ingredient_names: ingredientNameSlice,
    allergens: allergenSlice,
    traces: traceSlice,
    durabilities: DurabilitySlice,
    durability_comments: DurabilityCommentSlice,
    orders: orderSlice,
    purchase_lists: purchaseListSlice,
    cart: persistReducer(
        {
            key: 'cart',
            storage,
            keyPrefix: 'berry-'
        },
        cartReducer
    ),
    production: productionSlice,
    production_units: productionUnitSlice,
    mailers: mailerSlice,
    dashboard: dashboardSlice,
    amend_order_line_rules: amendOrderLineSlice,
    currencies: currencySlice,
    report: reportSlice,
    addresses: addressSlice,
    amend_order_rules: amendOrderSlice,
    entry_records: entryRecordSlice,
    entry_record_days: entryRecordDaySlice,
    employees: employeeSlice,
    employee_departments: employeeDepartmentSlice,
    employee_device_groups: employeeDeviceGroupSlice,
    employee_holidays: employeeHolidaySlice,
    payroll_data: payrollDataSlice,
    payroll_update_jobs: payrollUpdateJobSlice,
    kgroups: kgroupSlice,
    page: pageSlice,
    users: userSlice,
    user_settings: userSettingSlice,
    report_templates: reportTemplateSlice,
})

export default reducer;
